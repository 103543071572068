document.addEventListener('DOMContentLoaded', function() {

  // Show spinner on ajax:beforeSend and hide it on ajax:complete
  document.querySelectorAll('.show-spinner').forEach(function(elem) {
    elem.addEventListener('ajax:beforeSend', function() {
      document.querySelector('.spinner').classList.toggle('hide');
    });

    elem.addEventListener('ajax:complete', function() {
      document.querySelector('.spinner').classList.toggle('hide');
    });
  });

  // Handle click event for buttons with class 'click-btn'
  document.addEventListener('click', function(e) {
    const target = e.target;
    if (target.matches('a.click-btn')) {
      e.preventDefault();
      const updateUrl = target.dataset.updateUrl;
      const updateMethod = target.dataset.updateMethod || 'post';

      $.ajax({
        url: updateUrl,
        method: updateMethod,
        dataType: 'json',
        success: function(response) {
          if (target.classList.contains('feature')) {
            target.classList.toggle('btn-warning');
            target.classList.toggle('btn-success');
          } else {
            target.classList.toggle('btn-primary');
            target.classList.toggle('btn-success');
          }

          const newMethod = updateMethod === 'post' ? 'delete' : 'post';
          target.dataset.updateMethod = newMethod;
          target.dataset.updateUrl = response.url;
          target.setAttribute('data-update-url', response.url);
        },
        error: function(xhr) {
          const response = JSON.parse(xhr.responseText);
          alert(response.errors[0] || 'Request failed');
        }
      });
    }
  });

  // Filter elements based on the input in #filter
  const filterInput = document.querySelector('#filter');
  if (filterInput) {
    filterInput.addEventListener('keyup', function() {
      const filterValue = this.value.toLowerCase();
      const regex = new RegExp(filterValue, 'i');

      document.querySelectorAll('.search-btn').forEach(function(btn) {
        if (btn.textContent.search(regex) < 0) {
          btn.closest('div').style.display = 'none';
        } else {
          btn.closest('div').style.display = '';
        }
      });

      if (!filterValue) {
        document.querySelectorAll('.searchable button').forEach(function(btn) {
          btn.closest('div').style.display = '';
          btn.style.display = '';
        });
      }
    });
  }

  // Filter links based on the input in #filter
  if (filterInput) {
    filterInput.addEventListener('keyup', function() {
      const filterValue = this.value.toLowerCase();
      const regex = new RegExp(filterValue, 'i');

      document.querySelectorAll('a.link').forEach(function(link) {
        if (link.textContent.search(regex) < 0) {
          link.closest('div').style.display = 'none';
        } else {
          link.closest('div').style.display = '';
        }
      });

      if (!filterValue) {
        document.querySelectorAll('a.link').forEach(function(link) {
          link.closest('div').style.display = '';
        });
      }
    });
  }

  // Filter names based on the input in #filter
  if (filterInput) {
    filterInput.addEventListener('keyup', function() {
      const filterValue = this.value.toLowerCase();
      const regex = new RegExp(filterValue, 'i');

      document.querySelectorAll('td.name').forEach(function(td) {
        if (td.textContent.search(regex) < 0) {
          td.closest('tr').style.display = 'none';
        } else {
          td.closest('tr').style.display = '';
        }
      });

      if (!filterValue) {
        document.querySelectorAll('td.name').forEach(function(td) {
          td.closest('tr').style.display = '';
        });
      }
    });
  }

  // Handle keyup events for dynamic attributes
  const atrCont = document.querySelectorAll('.attributeContainer');
  if (atrCont) {
    atrCont.forEach(function(container) {
      container.addEventListener('keyup', function(e) {
        if (e.target.matches('.dynamicAttributeName')) {
          const nameElem = e.target;
          const valueElem = nameElem.closest('.rm').querySelector('.text_field');
          const value = nameElem.value.toLowerCase();

          valueElem.setAttribute('id', 'user_settings_' + value);
          valueElem.setAttribute('name', 'user[settings][' + value + ']');
          valueElem.setAttribute('placeholder', 'value for ' + value);
        }
      });

      container.addEventListener('click', function(e) {
        if (e.target.matches('.remove')) {
          const form = e.target.closest('form');
          const field = e.target.closest('div').previousElementSibling.querySelector('input[type=text]').value;
          e.target.closest('.rm').remove();

          if (field !== '') {
            form.submit();
          }
        }
      });
    });
  }

  // Handle adding dynamic attributes
  const addAttr = document.querySelector('.addAttribute');
  if (addAttr) {
    addAttr.addEventListener('click', function() {
      const template = document.querySelector('.attributeTemplate').innerHTML;
      const newAttribute = document.createElement('div');
      newAttribute.classList.add('rm');
      newAttribute.innerHTML = template;
      this.insertAdjacentElement('beforebegin', newAttribute);
    });
  }

  // Show/Hide QR code
  const showQr = document.querySelector('.showQr')
  if (showQr) {
    document.querySelector('.showQr').addEventListener('click', function() {
      document.querySelector('.qr').classList.toggle('hide');
    });
  }

  // Submit form on adding attribute
  if (atrCont) {
    atrCont.forEach(function(container) {
      container.addEventListener('click', function(e) {
        if (e.target.matches('.adder')) {
          const form = e.target.closest('form');
          e.target.remove();
          form.submit();
        }
      });
    });
  }

  // Handle pagination links
  const paginateLinks = document.querySelectorAll('.paginate_links')
  if (paginateLinks) {
    paginateLinks.forEach(function(pagination) {
      pagination.addEventListener('click', function(e) {
        if (e.target.matches('.pagination a')) {
          e.preventDefault();
          $.get(e.target.href, null, null, 'script');
        }
      });
    });
  }

  // AJAX CAS logout
  const ajaxLogout = document.querySelector('a#ajax_cas_logout')
  if (ajaxLogout) {
    ajaxLogout.addEventListener('ajax:success', function(evt, data) {
      if (data.logout) {
        alert('User Was Logged-out from all Instances');
      } else {
        alert('User Was NOT Logged-out from all Instances');
      }
    });
    ajaxLogout.addEventListener('ajax:error', function() {
      console.log('Cannot log-out User');
    });
  }


  // Submit form on organization search change
  const userOrgSearch = document.querySelector('#users_org_search')
  if (userOrgSearch) {
    userOrgSearch.addEventListener('change', function() {
      this.closest('form').submit();
    });
  }

});

// Re-run ready function when Turbo Drive replaces the page
document.addEventListener('turbo:load', function() {
  ready();
});
