$(function() {
    // checks if the user is loged-in
    var userLogedIn = $("div#isUserLogedIn").data("user");

    if (userLogedIn) {
        // Timeout warning
        // check every 30 sec, if dialog is up every 5 sec
        var timeoutCheckInterval = setInterval(checkTimeOut, 30000);
        var remainingSeconds
    }

    function checkTimeOut() {
        var sessionTimeoutDialog = $("#sessionTimeoutDialog");
        initTimeoutDialog(sessionTimeoutDialog);
        $.ajax({
            type: "GET",
            url: "/session_timeout/check_time_until_logout",
            cache: false,
            success: function(data, status, xhr) {
                if (data.message <= 4){
                    document.location = '/users/sign_out';
                } else if (data.message > 4 && data.message < 90) {
                    remainingSeconds = data.message
                    initTimeoutDialog(sessionTimeoutDialog);
                    sessionTimeoutDialog.data('seconds', data.message).dialog("open");
                } else {
                    if (sessionTimeoutDialog.dialog('isOpen')) {
                        sessionTimeoutDialog.dialog("close");
                    }
                    clearInterval(timeoutCheckInterval);
                    timeoutCheckInterval = setInterval(checkTimeOut, 30000) // set to 30 sec
                }
            },
            error: function() {}
        });
    };

    // timeOut Dialog
    function initTimeoutDialog(sessionTimeoutDialog) {
        sessionTimeoutDialog.dialog({
            autoOpen: false,
            modal: true,
            width: 400,
            height: 200,
            closeOnEscape: false,
            draggable: false,
            resizable: false,
            title: "Your session is about to expire! (" + remainingSeconds + ")",
            open: function(event, ui) {
                $(".ui-dialog-titlebar-close").hide();
                clearInterval(timeoutCheckInterval)
                timeoutCheckInterval = setInterval(checkTimeOut, 5000) // while open set to 5 sec
            },
            buttons: {
                'Yes, Keep Working': function() {
                    $.ajax({
                        type: "GET",
                        dataType: 'script',
                        url: "/session_timeout/reset_user_clock"
                    });
                    clearInterval(timeoutCheckInterval);
                    timeoutCheckInterval = setInterval(checkTimeOut, 30000) // set to 30 sec
                    $(this).dialog('close');
                },
                'No, Logoff': function() {
                    $(this).dialog('close');
                    clearInterval(timeoutCheckInterval);
                    $.ajax({
                      url: '/users/sign_out',
                      type: 'GET',
                      success: function(result) {
                          document.location = '/';
                      }
});
                }
            }
        })
    };
});
